import styles from './styles.module.css'
import glaza from '@assets/smiles/glaza.svg'
import { ListSearch } from '../../components'
import { useSelector } from "react-redux";
import { selectSearch } from "../../modules/search.selector";

export const Search = () => {
    const search = useSelector(selectSearch)

    return (
        <div className={styles.container}>
            {
                !search && (
                    <div className={styles.startText}>
                        Найдите учебник или упражнение по тексту
                    </div>
                )
            }
            {
                search?.length === 0 && (
                    <div className={styles.noSearch}>
                        <img alt={'/'} src={glaza} />
                        Мы ничего не нашли...
                    </div>
                )
            }
            {
                search?.length > 0 && (
                    <>
                        <h1 className={styles.title}>Упражнения</h1>
                        <ListSearch />
                    </>
                )
            }
        </div>
    );
}

export default Search;
