import { useSelector } from "react-redux";
import { selectGroupBook } from "../../../pages/Book/select";
import styles from './styles.module.css'


export const FirstGrid = ({ setPage }) => {
    const exercises = useSelector(selectGroupBook)
    return (
        <div className={styles.container}>
            {
                Object.keys(exercises)?.map((key) => (
                    <div key={key} className={styles.exercise} onClick={() => setPage(key)}>{exercises[key].firstNumber} - {exercises[key].lastNumber}</div>
                ))
            }
        </div>
    )
}
