import styles from './styles.module.css'

export const Progress = ({ progress = 20, count = 100 }) => {
    const percent = progress ? (progress / count) * 100 : 0;
    return (
        <div className={styles.container}>
            <div className={styles.proggres} style={{ width: `${percent}%` }} />
        </div>
    )
}
