import dayjs from "dayjs";
import 'dayjs/locale/ru';

dayjs.locale('ru')
const formatDayA = dayjs().format('A')

export const generateSlideItems = (array, maxSize = 3) => {
    if (!array) {
        return new Array(maxSize).fill(null)
    }
    if (array.length < maxSize) {
        const notEmptyCount = maxSize - array.length;
        const notEmptyArray = new Array(notEmptyCount).fill(null)
        return [...array, ...notEmptyArray]
    }
    return array
}

export function throttle(func, ms) {

    let isThrottled = false,
        savedArgs,
        savedThis;

    function wrapper() {

        if (isThrottled) {
            savedArgs = arguments;
            savedThis = this;
            return;
        }

        func.apply(this, arguments);

        isThrottled = true;

        setTimeout(function() {
            isThrottled = false;
            if (savedArgs) {
                wrapper.apply(savedThis, savedArgs);
                savedArgs = savedThis = null;
            }
        }, ms);
    }

    return wrapper;
}

export const logOut = () => {
    localStorage.removeItem('jwt')
}

export const getClassName = {
    1: 'Первоклассник',
    2: 'Второклассник',
    3: 'Третьеклассник',
    4: 'Четвероклассник',
    5: 'Пятиклассник',
    6: 'Шестиклассник',
    7: 'Семиклассник',
    8: 'Восьмиклассник',
    9: 'Девятиклассник',
    10: 'Десятиклассник',
    11: 'Одиннадцатиклассник',
}

const dayTextExample = {
    'вечера': 'отличный вечер',
    'утра': 'отличное утро',
    'ночи': 'отличная ночь',
    'дня': 'отличный день',
};

export const formatPhoneNumber = (str) => {
    if (!str) return null;
    let cleaned = ('' + str).replace(/\D/g, '');
    let match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);
    if (match) {
        return `+7 ${match[2]} ${match[3]}-${match[4]}-${match[5]}`
    }
}

export const sortExercises = (arr) => arr?.slice()?.sort((a, b) => {
    if (a.number === b.number) {
        return a.subnumber - b.subnumber
    }
    return a.number - b.number
})

export const sortNumbersWithStrings = (arr) => {
    const splitNumbers = arr?.filter((item) => Number.isInteger(+item.subnumber))?.sort((a, b) => +a.subnumber - +b.subnumber) || []
    const splitString = arr?.filter((item) =>  !Number.isInteger(+item.subnumber))?.sort((a, b) => a.subnumber.localeCompare(b.subnumber)) || []
    return [...splitNumbers, ...splitString]
}

export const sortNumbersWithStringsSimple = (arr) => {
    const splitNumbers = arr?.filter((item) => Number.isInteger(+item))?.sort((a, b) => +a - +b) || []
    const splitString = arr?.filter((item) =>  !Number.isInteger(+item))?.sort((a, b) => a.localeCompare(b)) || []
    return [...splitNumbers, ...splitString]
}

export const getCurrentTextDay = () => {
    return dayTextExample[formatDayA] || dayTextExample['утра'];
}
