import styles from './styles.module.css'
import { ButtonImage } from '../../shared'
import close_img from '../../../assets/svg/close.svg'
import close_grey from '../../../assets/svg/close_grey.svg'
import check from '../../../assets/svg/check.svg'
import { useHistory } from 'react-router-dom'
import { navigationLink } from '../../../consts'
import cn from 'classnames'

export const Tab = ({ exercises, handelChangeTab, activeTabId, setVideoIndex }) => {
    const history = useHistory();
    const onChangeTab = (index) => {
        handelChangeTab(index)
        setVideoIndex(0)
    }

    return (
        <div className={styles.container}>
            <div className={styles.left}>
                {
                    exercises?.map((item, index) => {
                        const isDone = item?.explanations?.some((item) => !!item?.clear) && check;
                        const isNotDone = item?.explanations?.every((item) => item?.clear === false) && close_grey;
                        return (
                            <div key={item.id} className={styles.tab} onClick={() => onChangeTab(index)}>
                            <span
                                className={cn(
                                    styles.text,
                                    {
                                        [styles.notDone]: isNotDone,
                                        [styles.done]: activeTabId === index || isDone,
                                    }
                                )}
                            >
                                {item.number}{item?.subnumber ? `(${item?.subnumber})` : ''}
                                {isDone && <img alt='/' src={isDone} />}
                                {isNotDone && <img alt='/' src={isNotDone} />}
                            </span>
                            </div>
                        )
                    })
                }
            </div>
            <ButtonImage image={close_img} onClick={() => history.push(navigationLink.mainboard)}>Завершить домашку</ButtonImage>
        </div>
    );
}

export default Tab;
