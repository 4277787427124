import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isOpenModalSub: false,
}

export const controllersStore = createSlice({
    initialState,
    name: 'controllersStore',
    reducers: {
        closeSubModal: (state) => {
            state.isOpenModalSub = false
        },
        openSubModal: (state) => {
            state.isOpenModalSub = true
        },
    },
})

export const { openSubModal, closeSubModal } = controllersStore.actions
export default controllersStore.reducer
