import React, {useEffect, useState} from "react";
import { fetchUserById } from "../../modules/user";
import { useDispatch, useSelector } from "react-redux";
import styles from './styles.module.css';
import { useHistory } from "react-router-dom";
import profile_photo from '@assets/svg/profile_photo.svg'
import edit from '@assets/svg/edit.svg'
import close_orange from '@assets/svg/close_orange.svg'
import check_round from '@assets/svg/check_round.svg'
import master from '../../assets/svg/mc_vrt_pos.svg'
import visalogo from '../../assets/svg/visalogo.svg'
import mir from '../../assets/svg/mir.svg'
import { getClassName, formatPhoneNumber } from '../../utils/helper'
import { Button, Input, ButtonImage } from "@shared";
import cn from 'classnames'
import { putReq, postReqFile, getImage, postReq } from "../../utils";
import { pathsApi } from "../../consts";
import sad from '../../assets/smiles/sad.svg'
import { content as contentSub } from '../../components/Subscribe/ModalSub'
import dayjs from 'dayjs'
import closeGrey from "../../assets/svg/close_grey.svg";
import edit_orange from "../../assets/svg/edit_orange.svg";
import { openSubModal } from "../../modules/controllers";
import { useClassList } from "../../hooks";

const CLASSES = new Array(11).fill(0).map((item, index) => `${index + 1} класс`);
const cardLogs = {
  visa: visalogo,
  mastercard: master,
  mir
};
export const Profile = () => {
    const dispatch = useDispatch()
    const user = useSelector(state => state?.user?.user)
    const [step, setStep] = useState(0);
    const [name, setName] = useState('');
    const [activeClass, setClass] = useState(null);
    const [fileState, setFile] = useState(null);
    const [image, setImage] = useState('');
    const [imagePrev, setImagePrev] = useState('');
    const history = useHistory();
    const classesList = useClassList()

    useEffect(() => {
        setName(user?.username || '')
        setClass(user?.classNumber || '')
        setImage(user?.avatar?.url || '')
    },[user])

    const handleBack = () => {
        history.goBack();
    }

    const onBack = () => {
        setStep(0)
        setImagePrev('')
        setImage(user?.avatar?.url)
    }

    const handleChange = (event) => {
        setName(event.target.value);
    };

    const handleImageChange = (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let form = new FormData();
        let file = e.target.files[0];
        reader.onloadend = () => {
            setImage(reader.result)
            setImagePrev(reader.result)
        }

        form.append("files", file);
        reader.readAsDataURL(file)
        setFile(form)
    }
    const submitAvatar = async () => {
        const dataAvatar = await postReqFile(pathsApi.addAvatar, fileState)
        if (!dataAvatar?.error) {
            dispatch(fetchUserById())
            setStep(0)
        } else (
            console.log('error')
        )
    }

    const onSubmit = async () => {
        const data = await putReq(`${pathsApi.users}/${user.id}`, {
            username: name,
            classNumber: activeClass,
        })
        if (data && data.username) {
            if (fileState) {
                await submitAvatar()
            } else {
                dispatch(fetchUserById())
                setStep(0)
            }
        } else {
            console.log('error')
        }
    }
    const onCloseSub = async () => {
        const data = await postReq(pathsApi.revoke, {})
        if (data) {
            dispatch(fetchUserById())
        } else {
            console.log('error')
        }
    }
    const textTitle = step === 0 ? 'Профиль' : 'Редактировать профиль'
    const subscribeInfo = user?.subscribeInfo
    const currentInfoSub = subscribeInfo?.purchaseCycle && contentSub.find((item) => item.value === subscribeInfo?.purchaseCycle);

    const imgPreview = imagePrev ? imagePrev : image ? getImage(image) : profile_photo
    const isClassView = (date) => {
        if (date) {
            return dayjs().isBefore(dayjs.unix(date))
        } else {
            return false
        }
    }
    return (
        <div className={styles.container}>
            <button className={styles.back} onClick={handleBack}>
                <div className={styles.arrow} />
                <span>Назад</span>
            </button>
            <div className={styles.titleBlock}>
                <h1 className={styles.title}>{textTitle}</h1>
                {
                    step === 0 && (
                        <Button width={192} imageLeft={edit} onClick={() => setStep(1)}>
                            Редактировать
                        </Button>
                    )
                }
                {
                    step === 1 && (
                        <div className={styles.buttons}>
                            <Button
                                width={129}
                                imageLeft={close_orange}
                                onClick={onBack}
                                opacityButton
                                color={'rgba(255, 107, 85, 0.08)'}
                            >
                                Отмена
                            </Button>
                            <div className={styles.marginLeft}>
                                <Button width={154} imageLeft={check_round} onClick={onSubmit}>
                                    Сохранить
                                </Button>
                            </div>
                        </div>
                    )
                }

            </div>
            {
                step === 0 && (
                    <div className={styles.content}>
                        <div className={styles.avatar}>
                            <img
                                className={cn(styles.avatarImg, { [styles.defaultAvatar]: !user?.avatar })}
                                alt="/"
                                src={image ? getImage(image) : profile_photo}
                            />
                        </div>
                        <span className={styles.name}>{user?.username}</span>
                        <span className={styles.classText}>{getClassName[user?.classNumber] || ''}</span>
                        <span className={styles.phone}>{formatPhoneNumber(user?.phone)}</span>
                        {
                            user?.activeSubscribe ? (
                                <div className={styles.subscrube}>
                                    <div className={styles.subTitle}>Подписка</div>
                                    <div className={styles.subBlocks}>
                                        <div className={styles.blockInfo}>
                                            <div className={styles.titleSubInfo}>{currentInfoSub?.title}</div>
                                            <div className={styles.amount}>{subscribeInfo?.amount.toString()} ₽</div>
                                            <div className={styles.descriptionInfoSub}>{currentInfoSub?.info}</div>
                                        </div>
                                        <div className={styles.blockInfo}>
                                            <div className={styles.topInfoCard}>
                                                <img alt='/' src={cardLogs[subscribeInfo?.paymentSystem?.toLowerCase()]} className={styles.cardLogo} />
                                                <div className={styles.numberCard}>
                                                    {subscribeInfo?.cardPan ? subscribeInfo.cardPan.substr(8).replaceAll('X', '*') : '-'}
                                                    <div className={styles.dateCard}>
                                                        {subscribeInfo?.cardExpiration}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={styles.nextPurchase}>
                                                Следующее списание <br />
                                                {
                                                    subscribeInfo?.status !== 'Revoke' ?
                                                        subscribeInfo?.nextPurchase ? dayjs.unix(subscribeInfo?.nextPurchase).format('DD.MM.YYYY') : '-' : 'Подписки нет'
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.buttonMargin}>
                                        <ButtonImage
                                            onClick={() => dispatch(openSubModal())}
                                            imageLeft={edit_orange}
                                            fontWeight={500}
                                        >
                                            Изменить план подписки
                                        </ButtonImage>
                                    </div>
                                    <ButtonImage
                                        onClick={onCloseSub}
                                        imageLeft={closeGrey}
                                        fontWeight={500}
                                        color={'#858585'}
                                    >
                                        Отменить подписку
                                    </ButtonImage>
                                </div>
                            ) : (
                                <div className={styles.subscrube}>
                                    <div className={styles.subTitle}>Подписка</div>
                                    <p className={styles.textNoSub}>
                                        Без подписки.<img alt='/' src={sad} />
                                        <br />
                                        Получи полный доступ ко всем материалам
                                    </p>
                                    <Button
                                        onClick={() => dispatch(openSubModal())}
                                        color={'#6200E5'}
                                    >
                                        Офорить подписку
                                    </Button>
                                </div>
                            )
                        }
                    </div>
                )
            }
            {
                step === 1 && (
                    <div className={styles.editContent}>
                        <div className={styles.left}>
                            <div className={styles.avatar}>
                                <img
                                    className={cn(styles.avatarImg, { [styles.defaultAvatar]: !image })}
                                    alt="/"
                                    src={imgPreview}
                                />
                            </div>
                            <div className={styles.containerUpload}>
                                <input
                                    className={styles.uploadFile}
                                    type="file"
                                    onChange={handleImageChange}
                                    accept="image/x-png,image/jpeg,image/gif"
                                />
                                <div className={styles.editText}>Изменить</div>
                            </div>
                        </div>
                        <div className={styles.right}>
                            <div className={styles.inputBlock}>
                                <Input
                                    label="Как тебя зовут?"
                                    value={name}
                                    onChange={handleChange}
                                />
                            </div>
                            <h3>В каком ты классе?</h3>
                            <div className={styles.classes}>
                                {
                                    CLASSES.map((item, index) => (
                                        <div
                                            key={index}
                                            className={cn(
                                                styles.class,
                                                {
                                                    [styles.activeClass]: activeClass === index + 1,
                                                    [styles.disabledClass]: isClassView(classesList?.schedule?.[index]?.date)
                                                })
                                            }
                                            onClick={() => !isClassView(classesList?.schedule?.[index]?.date) ? setClass(index + 1) : undefined}
                                        >
                                            {item}
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    );
}

export default Profile;
