import { Link } from "react-router-dom";
import cn from 'classnames'

import logo from '@assets/svg/logo.svg'
import styles from './footer.module.css'
import facebook from '@assets/svg/facebook.svg'
import instagram from '@assets/svg/instagram.svg'
import patreon from '@assets/svg/patreon.svg'
import telegram from '@assets/svg/telegram.svg'
import tiktok from '@assets/svg/tiktok.svg'
import vk from '@assets/svg/vk.svg'
import support from '@assets/svg/support.svg'

const list = [
    { id: 1, image: facebook, link: '/' },
    { id: 2, image: telegram, link: '/' },
    { id: 3, image: vk, link: '/' },
    { id: 4, image: instagram, link: '/' },
    { id: 5, image: tiktok, link: '/' },
    { id: 6, image: patreon, link: '/' },
]

export const Footer = () => {
    return (
        <div className={styles.container}>
            <div className={styles.top}>
                <div>
                    <div className={styles.blockLogo}>
                        <img className={styles.logo} src={logo} alt="Logo" />
                        <span className={styles.text}>Сервис помощи <br/> со школьными заданиями</span>
                    </div>
                    <div className={styles.blockLogo} style={{cursor: 'pointer'}} onClick={()=> window.open('https://t.me/izidz_ask')}>
                        <img className={styles.logo} width={40} src={support} alt="Support" />
                        <div style={{marginLeft: 42}}/>
                        <span className={styles.text}>Техподдержка в Telegram</span>
                    </div>
                    {/*<ul className={styles.list}>
                        {
                            list.map((item) => (
                                <li key={item.id} className={styles.link}>
                                    <Link to={item.link}>
                                        <img src={item.image} alt={item.id} />
                                    </Link>
                                </li>
                            ))
                        }
                    </ul>*/}
                </div>
            </div>
            <div className={styles.bottom}>
                <span className={styles.greyText}>© 2024 IZIDZ <a href='mailto:izidzwork@gmail.com'>izidzwork@gmail.com</a></span>
                <div>
                    <a className={styles.greyText} href="https://app.izidz.com/documents/eula.pdf" target='_blank'>Пользовательское соглашение</a>
                    <a className={cn(styles.greyText, styles.lastLink)} href="https://app.izidz.com/documents/privacy-policy.pdf" target='_blank'>Политика конфиденциальности</a>
                </div>
            </div>
        </div>
    );
}

export default Footer;
