import styles from './styles.module.css'
import cn from 'classnames'

export const ButtonImage = ({
    children,
    onClick = null,
    disabled = false,
    opacityButton = false,
    image,
    imageLeft,
    color = '#FF6B55',
    fontWeight = 600
}) => {
    return (
        <button
            className={cn(styles.container, { [styles.opacityButton]: opacityButton })}
            onClick={onClick}
            disabled={disabled}
            style={{ color, fontWeight }}
        >
            {
                imageLeft && <img className={styles.leftImage} alt="/" src={imageLeft} />
            }
            {children}
            {
                image && <img className={styles.image} alt="/" src={image} />
            }
        </button>
    );
}

export default ButtonImage;
