import Dialog from '@material-ui/core/Dialog';
import styles from './styles.module.css'
import { Button } from "../../shared";
import MuiDialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import cn from "classnames";
import { addExercise, deleteExercise } from "../../../modules/books";
import { useDispatch, useSelector } from "react-redux";
import { selectSelectedExercise } from "../../../pages/Book/select";
import lock from "../../../assets/svg/lock.svg";
import {openSubModal} from "../../../modules/controllers";
import { sortNumbersWithStrings } from '../../../utils'

const DialogContent = withStyles(() => ({
    root: {
        padding: 24,
        width: 504,
        boxSizing: 'border-box'
    },
}))(MuiDialogContent);

export const ModalComponent = ({ handleClose, open, content }) => {
    const exercisesData = useSelector(selectSelectedExercise)
    const dispatch = useDispatch();
    const activeSubscribe = useSelector(state => state?.user?.user?.activeSubscribe)
    const handleClick = (item) => {
        if (!item?.freeAccess && !activeSubscribe) {
            return dispatch(openSubModal());
        }
        if (!exercisesData?.[item.id]) {
            dispatch(addExercise(item))
        } else {
            dispatch(deleteExercise(item))
        }
    }
    const onClear = () => {
        Object.keys(exercisesData).forEach((key) => {
            if (exercisesData[key].number === content[0].number) {
                dispatch(deleteExercise(exercisesData[key]))
            }
        })
    }
    const onAllAdd = () => {
        content.forEach((item) => {
            dispatch(addExercise(item))
        })
    }
    const sortingArray = sortNumbersWithStrings(content)

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogContent>
                <span className={styles.title}>Подпункты</span>
                <p className={styles.description}>У этого задания есть подпункты, выбери нужные</p>
                <div className={styles.content}>
                    {
                        sortingArray?.map((item) => (
                            <div
                                className={cn(styles.exercise, { [styles.selected]: exercisesData[item.id] })}
                                key={item.id}
                                onClick={() => handleClick(item)}
                            >
                                {item.subnumber}
                                {!activeSubscribe && !item?.freeAccess && <img className={styles.lock} src={lock} alt='/' />}
                            </div>
                        ))
                    }
                </div>
                <div className={styles.controllers}>
                    <div onClick={onAllAdd} className={styles.link}>Выбрать все</div>
                    <div
                        onClick={onClear}
                        className={cn(styles.link, { [styles.linkDisabled]: !Object.keys(exercisesData).length })}
                    >
                        Сбросить выбор
                    </div>
                </div>
                <div className={styles.navigation}>
                    <Button width={208} onClick={handleClose} color={'rgba(255, 107, 85, 0.08)'} opacityButton>
                        Отмена
                    </Button>
                    <Button width={208} onClick={handleClose}>
                        Готово
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
}

export default ModalComponent;
