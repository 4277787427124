import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getReq } from "../utils";
import { pathsApi } from "../consts";

export const fetchUserById = createAsyncThunk(
    'users/getById',
    async () => {
        const response = await getReq(pathsApi.usersMe)
        return response
    }
)

export const userStore = createSlice({
    initialState: {
        user: {},
        phone: ''
    },
    name: 'user',
    reducers: {
        clearUser: (state, action) => {
            state.user = {}
        },
        setPhone: (state, action) => {
            state.phone = action.payload
        },
        clearPhone: (state) => {
            state.phone = ''
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUserById.fulfilled, (state, action) => {
            state.user = action.payload
        })
    },
})
export const { clearUser, setPhone, clearPhone } = userStore.actions
export default userStore.reducer
