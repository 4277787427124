import styles from "./styles.module.css";
import Dialog from "@material-ui/core/Dialog";
import Stories from 'react-insta-stories';

export const ModalHistory = ({ handleClose, open, stories, indexStory }) => {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            PaperProps={{
                style: {
                    padding: 0,
                    overflow: "hidden",
                    maxWidth: 500
                }
            }}
        >
            <div className={styles.contentModal}>
                {
                    !!stories.length && (
                        <Stories
                            stories={stories}
                            defaultInterval={2000}
                            width='100%'
                            height='100%'
                            currentIndex={indexStory}
                        />
                    )
                }
            </div>
        </Dialog>
    )
}
