import styles from './styles.module.css'
import {ButtonImage, Button, SimpleModal} from "../../shared";
import play_left from '../../../assets/svg/play_left.svg'
import play_right from '../../../assets/svg/play_right.svg'
import info_img from '../../../assets/svg/info.svg'
import salute from '../../../assets/smiles/salute.svg'
import think from '../../../assets/smiles/think.svg'
import { ModalEnd } from '../ModalEnd/ModalEnd'
import { ModalNextVideo } from '../ModalNextVideo/ModalNextVideo'
import { ModalDone } from '../ModalDone/ModalDone'
import { useState } from "react";
import { postReq } from "../../../utils";
import { pathsApi, navigationLink } from "../../../consts";
import {fetchProgress} from "../../../modules/progress";
import {useDispatch} from "react-redux";
import { useHistory } from 'react-router-dom'

export const Controllers = ({
    activeContent,
    activeVideoIndex,
    setVideoIndex,
    handleNextExercises,
    handlePrevVideo,
    handleNextVideo,
    isLastExercises,
}) => {
    const dispatch = useDispatch()
    const [isOpenModalEnd, setModalEnd] = useState(false)
    const [isOpenModalNext, setModalNext] = useState(false)
    const [isOpenModalDone, setModalDone] = useState(false)
    const [isOpenNotMine, setModalNotMine] = useState(false)

    const history = useHistory();

    const handleModalNotMineOpen = () => setModalNotMine(true)
    const handleModalNotMineClose = () => setModalNotMine(false)

    const handleModalEndOpen = () => setModalEnd(true)
    const handleModalEndClose = () => setModalEnd(false)

    const handleModalNextOpen = () => setModalNext(true)
    const handleModalNextClose = () => setModalNext(false)

    const handleModalDoneOpen = () => setModalDone(true)
    const handleModalDoneClose = () => setModalDone(false)

    const lengthVideo = activeContent?.explanations?.length;
    const isDisabledPrevVideo = !activeVideoIndex;
    const isDisabledNextVideo = !lengthVideo || activeVideoIndex === lengthVideo - 1;
    const onOpenModal = () => {
        if (isDisabledNextVideo) {
            handleModalEndOpen()
        } else {
            handleModalNextOpen()
        }
    }

    const onSuccessExercises = (isDone) => {
        submitAnswer(!!isDone, activeContent?.explanations?.map(item => item?.id))
        if (!isLastExercises) {
            handleNextExercises()
        } else {
            handleModalDoneOpen()
        }
    }

    const submitAnswer = async (clear, explanationIds) => {
        try {
            const data = await postReq(pathsApi.addProgress, {
                explanationIds,
                clear
            })
            if (data) {
                dispatch(fetchProgress())
            } else {
                console.log(data)
            }
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className={styles.controllers}>
            <div className={styles.bottom}>
                <span className={styles.number}>
                    №{activeContent?.number}{activeContent?.subnumber ? `(${activeContent?.subnumber})` : ''}
                </span>
            </div>
            <ModalEnd
                open={isOpenModalEnd}
                handleClose={handleModalEndClose}
                setVideoIndex={setVideoIndex}
                handleNextExercises={onSuccessExercises}
            />
            <ModalNextVideo
                open={isOpenModalNext}
                handleClose={handleModalNextClose}
                handleNextExercises={onSuccessExercises}
                handleNextVideo={handleNextVideo}
            />
            <ModalDone handleClose={handleModalDoneClose} open={isOpenModalDone} />
            <SimpleModal
                open={isOpenNotMine}
                handleClose={handleModalNotMineClose}
                title='Это не моё задание'
                description={`К сожалению, такое бывает, и задания в разных редакциях могут не совпадать.
                Воспользуйтесь поиском, чтобы найти своё задание.`}
                onSubmit={() => history.push(navigationLink.search)}
                submitName={'Найти задание по тексту'}
                widthClose={130}
            />
        </div>
    );
}

export default Controllers;
