import styles from "./styles.module.css";
import { postReq } from "../../utils";
import { pathsApi, navigationLink } from "../../consts";
import { ViewBook } from '../../components/shared'
import { Button } from "../../components/shared";
import { useHistory } from 'react-router-dom'
import {useDisciplinesList} from "../../hooks/useDisciplines";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectUserBooks} from "../../modules/user.selector";
import {fetchUserById} from "../../modules/user";

export const AddBook = () => {
    const disciplines = useDisciplinesList()
    const books = useSelector(selectUserBooks)
    const [checked, setCheck] = useState({})
    const [removing, setRemove] = useState({})
    const history = useHistory()
    const dispatch = useDispatch()

    const onSave = async () => {
        const ids = {}
        Object.keys(checked).forEach(item => {
            const isSave = books?.find((book) => item === book.id.toString())

            if (!isSave) {
                ids[item] = item;
            }
        })

        Object.keys(removing).forEach(item => {
            const isSave = books?.find((book) => item === book.id.toString())

            if (isSave) {
                ids[item] = item;
            }
        })


        const data = await postReq(pathsApi.saveBooks, {
            bookIds: Object.values(ids)?.map(item => item)
        })
        if (!data?.error) {
            dispatch(fetchUserById())
            history.push(navigationLink.mainboard)
        }
    }
    useEffect(() => {
        if (books) {
            if (!books.length) {
                return setCheck({})
            }
            const ids = {}
            books.forEach(item => {
                ids[item.id] = item.id;
            })
            setCheck({...checked, ...ids})
        }
    }, [books])

    return (
        <div>
            <h1 className={styles.title}>Давай знакомиться!</h1>
            <p className={styles.description}>
                Выбери свои учебники
            </p>
            <ViewBook
                checked={checked}
                disciplines={disciplines}
                handleCheck={setCheck}
                setRemove={setRemove}
                removing={removing}
                redirectToDashboard
            />
            <Button onClick={onSave} disabled={!Object.keys(checked).length}>
                Поехали
            </Button>
        </div>
    );
}

export default AddBook;
