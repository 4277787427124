import styles from './styles.module.css';

export const NotFound = () => {

    return (
        <div className={styles.container}>
            <h1 className={styles.title}>404</h1>
            <div className={styles.text}>Упс.. Такой страницы не найдено</div>
        </div>
    );
}

export default NotFound;
