import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { fetchBookById } from '../../modules/books'
import { useParams, useHistory } from 'react-router-dom'
import { FirstGrid, SecondGrid } from '@components'
import styles from './styles.module.css'
import { Button } from "@shared";
import { selectBook, selectExerciseView, selectSelectedExercise } from './select'
import { clear } from '../../modules/books'
import { navigationLink } from '@consts'
import {postReq, sortNumbersWithStringsSimple} from "../../utils";
import { pathsApi } from "../../consts";
import {render} from "@testing-library/react";
import {fetchUserById} from "../../modules/user";

export const Book = () => {
    const history = useHistory();
    const [activePage, setPage] = useState(1)
    const dispatch = useDispatch()
    const book = useSelector(selectBook)
    const exercises = useSelector(selectExerciseView)
    const selectExercises = useSelector(selectSelectedExercise)
    const { bookId, disciplineId } = useParams()

    useEffect(() => {
        dispatch(fetchBookById(bookId))
        return () => dispatch(clear())
    }, [])

    const handleBack = () => {
        history.push(navigationLink.mainboard)
    }
    const handleSubmit = async () => {
        try {
            const data = await postReq(pathsApi.addProgress, {
                exerciseIds: Object.keys(selectExercises).map((key) => key),
            })
            if (data) {
                history.push(`${navigationLink.exercise}/${disciplineId}`)
            } else {
                console.log(data)
            }
        } catch (e) {
            console.log(e)
        }
    }

    const isEmpty = Object.keys(exercises).length;

    return (
        <div className={styles.container}>
            <button className={styles.back} onClick={handleBack}>
                <div className={styles.arrow} />
                <span>Назад</span>
            </button>
            <div className={styles.content}>
                <div className={styles.left}>
                    <h1>{book?.discipline?.name}</h1>
                    {!activePage ? <FirstGrid setPage={setPage} /> : <SecondGrid activePage={activePage} />}
                </div>
                <div className={styles.right}>
                    <p className={styles.text}>Выбери номера из своего<br /> домашнего задания</p>
                    <div className={styles.exercises}>
                        {
                            Object.keys(exercises).sort((a, b) => a - b).map((key) => {
                                const subNumber = sortNumbersWithStringsSimple(exercises[key]?.subnumber?.split())?.join(',')
                                return (
                                    <div key={key} className={styles.exercisesBlock}>
                                        <p className={styles.number}>{key} <span className={styles.subnumber}>{subNumber}</span></p>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <Button width={233} onClick={handleSubmit} disabled={!isEmpty}>
                        Погнали
                    </Button>
                    {
                        !!isEmpty && (
                            <div className={styles.clear} onClick={() => dispatch(clear())}>Очистить выбор</div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}
