import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getReq } from "../utils";
import { pathsApi } from "../consts";
import {counterSlice} from "./example/example";

const initialState = {
    search: null,
}

export const fetchSearch = createAsyncThunk(
    'search/books',
    async (name, limit = 10) => {
        const response = await getReq(
            `${pathsApi.exercises}?_where[_or][0][description_contains]=${name}&_where[_or][1][number_contains]=${name}&_limit=10`
        )
        return response
    }
)

export const searchStore = createSlice({
    initialState,
    name: 'search',
    reducers: {
        clearSearch: (state, action) => {
           state.search = null
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchSearch.fulfilled, (state, action) => {
            state.search = action.payload
        })
    },
})
export const { clearSearch } = searchStore.actions
export default searchStore.reducer
