const getParams = (method, isNotJwt, isFile) => {
    const jwt = !isNotJwt ? { 'Authorization': `Bearer ${localStorage.getItem('jwt')}` } : {};
    return {
        method,
        headers: {
            'Content-Type': `${isFile ? 'multipart/form-data' : 'application/json'}`,
            ...jwt,
        }
    }
}

export const postReq = async (path, body, isNotJwt, isFile) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_URL}${path}`, {
            ...getParams('POST', isNotJwt, isFile),
            body: isFile ? body : JSON.stringify(body),
        });
        const data = await response.json();
        return data;
    } catch (e) {
        return e;
    }
}

export const putReq = async (path, body, isNotJwt) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_URL}${path}`, {
            ...getParams('PUT', isNotJwt),
            body: JSON.stringify(body)
        });
        const data = await response.json();
        return data;
    } catch (e) {
        return e;
    }
}

export const getReq = async (path, isNotJwt) => {
    const response = await fetch(`${process.env.REACT_APP_URL}${path}`, {
        ...getParams('GET', isNotJwt),
    });
    const data = await response.json();

    return data;
}

export const postReqFile = async (path, body) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_URL}${path}`, {
            ...getParamsFile('POST'),
            body,
        });
        const data = await response.json();
        return data;
    } catch (e) {
        return e;
    }
}

const getParamsFile = (method, i) => {
    return {
        method,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('jwt')}`,
        }
    }
}

export const getImage = (path) => `${process.env.REACT_APP_URL}${path}`
