import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { navigationLink } from '@consts'
import { Authorization, Profile, Dashboard, Book, Exercise, AddBook, Search, CheckPurchase, NotFound } from '@pages'
import { Wrapper } from '@components'
import NoSsr from '@material-ui/core/NoSsr';

const PrivateRoute = ({component: Component, ...rest}) => {
    return (
        <Route {...rest} render={props => (
            !!localStorage.getItem('jwt') ?
                <Component {...props} />
                : <Redirect to={navigationLink.authorization} />
        )} />
    );
};

const PublicRoute = ({component: Component, restricted, ...rest}) => {
    return (
        <Route {...rest} render={props => (
            !!localStorage.getItem('jwt') && restricted ?
                <Redirect to={navigationLink.mainboard} />
                : <Component {...props} />
        )} />
    );
};

function App() {
  return (
      <NoSsr>
          <Router>
              <Wrapper>
                  <Switch>
                      {/*<PublicRoute restricted={false} component={MainPage} path={navigationLink.main} exact />*/}
                      <PublicRoute restricted={true} component={Authorization} path={`${navigationLink.authorization}/:number?`} exact />
                      <PrivateRoute component={Profile} path={navigationLink.profile} exact />
                      <PrivateRoute component={Dashboard} path={navigationLink.mainboard} exact />
                      <PrivateRoute component={Book} path={`${navigationLink.books}/:disciplineId/:bookId`} exact />
                      <PrivateRoute component={Exercise} path={`${navigationLink.exercise}/:id`} exact />
                      <PrivateRoute component={AddBook} path={navigationLink.addBook} exact />
                      <PrivateRoute component={Search} path={navigationLink.search} exact />
                      <PrivateRoute component={CheckPurchase} path={`${navigationLink.check}`} exact />
                      <PublicRoute path='*' exact={true} component={NotFound} />
                  </Switch>
              </Wrapper>
          </Router>
      </NoSsr>
  );
}

export default App;
