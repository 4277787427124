import styles from './styles.module.css'
import button_add from '@assets/svg/button_add.svg'
import { navigationLink } from '@consts'
import { useHistory } from 'react-router-dom'
import { useSelector } from "react-redux";
import { selectUserBooks } from '../../../modules/user.selector'
import { ButtonImage } from "../../shared";
import arrow_orange from '../../../assets/svg/arrow_orange.svg'
import { AddModal } from '../AddModal/AddModal'
import {useState} from "react";
import {generateSlideItems, getImage} from "../../../utils";
import Tooltip from "@material-ui/core/Tooltip";
import cn from 'classnames'

export const MySubject = () => {
    const books = useSelector(selectUserBooks)
    const [isOpenModal, setModal] = useState(false)
    const history = useHistory()
    const handleNext = ({ discipline: disciplineId, id: bookId }) => {
        history.push(`${navigationLink.books}/${disciplineId}/${bookId}`)
    }
    const handleCloseModal = () => setModal(false)
    const handleOpenModal = () => setModal(true)
    const booksList = generateSlideItems(books, 2);
    return (
        <div className={styles.container}>
            <div className={styles.topContent}>
                <span className={styles.title}>Мои предметы</span>
                <button className={styles.button} onClick={handleOpenModal}>
                    <img className={styles.button_img} src={button_add} alt="add"/>
                    Добавить
                </button>
            </div>
            <div className={styles.content}>
                {
                    booksList?.map((item, index) => {
                        if (!item) {
                            return (
                                <div className={cn(styles.item, styles.bigContent)} key={index}>
                                    <div>
                                        <div className={styles.nameDefault} />
                                        <div className={styles.bookDescription}>
                                            <div className={styles.imageDefault} />
                                            <div className={styles.bookText}>
                                                <div className={styles.authorDefault} />
                                                <div className={styles.originDefault} />
                                                <div className={styles.originDefaultSecond} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        return (
                            <Tooltip
                                title={
                                    <div>
                                        <b>{item.name}</b>
                                        <p>{item.author}</p>
                                        <p>{item.origin}</p>
                                    </div>
                                }
                                key={index}
                            >
                                <div className={cn(styles.item, { [styles.bigContent]: booksList.length <= 2 })}>
                                    <div>
                                        <div className={styles.name}>{item.name}</div>
                                        <div className={styles.bookDescription}>
                                            <div className={styles.image}>
                                                <img alt='/' src={getImage(item?.cover?.url)} />
                                            </div>
                                            <div className={styles.bookText}>
                                                <div className={styles.author}>{item.author}</div>
                                                <div className={styles.origin}>{item.origin}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.contentBottom}>
                                        <ButtonImage image={arrow_orange} onClick={() => handleNext(item)} />
                                    </div>
                                </div>
                            </Tooltip>
                        )
                    })
                }
            </div>
            <AddModal open={isOpenModal} handleClose={handleCloseModal} />
        </div>
    );
}

export default MySubject;
