import volume_down from '../../../assets/svg/volume_down_white_24dp.svg'
import volume_off from '../../../assets/svg/volume_off_white_24dp.svg'
import volume_up from '../../../assets/svg/volume_up_white_24dp.svg'
const MAX_VALUE_TIME = 60;
const MAX_TIME_ZERO = 10;

export const getValidTime = (value) => value < MAX_TIME_ZERO ? `0${value}` : value;
export const getTimeBalance = (florTime, time) => florTime - (time * MAX_VALUE_TIME)

export const getTime = (value) => {
    const florTime = Math.floor(value)
    let hours = null;
    let minute = Math.floor(florTime / MAX_VALUE_TIME);
    let second = getTimeBalance(florTime, minute);
    second = getValidTime(second)
    if (minute > MAX_VALUE_TIME) {
        hours = Math.floor(minute / MAX_VALUE_TIME);
        minute = getTimeBalance(minute, hours);
    }
    minute = getValidTime(minute)
    hours = hours ? `${getValidTime(hours)}:` : ''
    return `${hours}${minute}:${second}`
}

export const getImageVolume = (value) => {
    if (!value) {
        return volume_off;
    } else if (value < 0.5) {
        return volume_down
    } else {
        return volume_up
    }
}
