import {useEffect, useRef, useState} from 'react'
import styles from "./smsField.module.css";



export const SmsField = ({ setValue, value, maxSmsLength = 6 }) => {
    const [ focused, setFocused ] = useState(false);
    const inputRef = useRef(null)
    const values = value.split("");
    const CODE_LENGTH = new Array(maxSmsLength).fill(0);

    const selectedIndex =
        values.length < CODE_LENGTH.length ? values.length : CODE_LENGTH.length - 1;

    useEffect(() => {
        inputRef.current.focus();
        }, [])
    const handleClick = () => {
        inputRef.current.focus();
    };
    const handleFocus = () => {
        setFocused(true);
    };
    const handleBlur = () => {
        setFocused(false);
    };

    const handleChange = (e) => {
        const value = e.target.value;
        setValue((prev) => {

            if (prev.length >= CODE_LENGTH.length) {
                return prev;
            }
            return (prev + value).slice(0, CODE_LENGTH.length)
        })
    };

    const handleKeyUp = (e) => {
        if (e.key === "Backspace") {
            setValue((prev) => prev.slice(0, prev.length - 1))
        }
    };
    const hideInput = !(values.length < CODE_LENGTH.length);

    return (
        <div className={styles.wrap} onClick={handleClick}>
            <input
                value=""
                ref={inputRef}
                onFocus={handleFocus}
                onBlur={handleBlur}
                className={styles.input}
                onChange={handleChange}
                onKeyUp={handleKeyUp}
                style={{
                    width: "56px",
                    top: "0px",
                    bottom: "0px",
                    left: `${selectedIndex * 56}px`,
                    opacity: hideInput ? 0 : 1,
                }}
            />
            {CODE_LENGTH.map((v, index) => {
                const selected = values.length === index;
                const filled =
                    values.length === CODE_LENGTH.length && index === CODE_LENGTH.length - 1;

                return (
                    <div className={styles.display} key={index}>
                        {values[index]}
                        {(selected || filled) && focused && <div className={styles.shadows} />}
                    </div>
                )})
            }
        </div>
    );
}
