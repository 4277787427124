import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getReq } from "../utils";
import { pathsApi } from "../consts";

const initialState = {
    data: {}
}

export const fetchProgress = createAsyncThunk(
    'progress/get',
    async () => {
        const response = await getReq(`${pathsApi.meProgress}`)
        return response
    }
)

export const progressStore = createSlice({
    initialState,
    name: 'progress',
    extraReducers: (builder) => {
        builder.addCase(fetchProgress.fulfilled, (state, action) => {
            state.data = action.payload
        })
    },
})

export default progressStore.reducer
