import styles from './styles.module.css'
import { ReactComponent as Arrow } from '@assets/svg/arrow.svg';
import { Button, MaskInput } from "@shared";
import { useState } from "react";
import { Second, Third, Fourth, Fiveth } from '../../components/MainPage'
import mainFirst from '../../assets/svg/mainFirst.svg'
import download_ios from '../../assets/svg/download_ios.svg'
import download_android from '../../assets/svg/download_android.svg'
import { navigationLink } from '../../consts'
import logo from '../../assets/svg/logo.svg'
import hi from '../../assets/smiles/hi.svg'
import { useHistory } from 'react-router-dom'
import {useDispatch, useSelector} from "react-redux";
import {setPhone} from "../../modules/user";

export const MainPage = () => {
    const phone = useSelector(state => state?.user?.phone)
    const dispatch = useDispatch();
    const history = useHistory()
    return (
        <div className={styles.container}>
            <div className={styles.first}>
                <div className={styles.firstLeft}>
                    <span className={styles.firstTitle}>
                        Привет! <img alt="/" src={hi} /> <br />
                        Это <img className={styles.logo} alt="/" src={logo} />
                    </span>
                    <p className={styles.firstDescription}>
                        Мы поможем тебе с домашкой! <br />
                        Во всем разберемся, сделаем непонятное <br /> понятным, а сложное – простым.
                    </p>
                    <div className={styles.formPhone}>
                        <MaskInput
                            onChange={(value) => dispatch(setPhone(value))}
                            value={phone}
                            label="Номер телефона"
                        />
                        <div className={styles.marginButtonLeft}>
                            <Button width={48} onClick={() => history.push(navigationLink.mainboard)}>
                                <Arrow />
                            </Button>
                        </div>
                    </div>
                    <div className={styles.firstPolicy}>
                        Продолжая, вы принимаете <br />
                        <a className={styles.link} href="https://app.izidz.com/documents/privacy-policy.pdf" target='_blank'>Пользовательское соглашение </a>
                        и <br />
                        <a className={styles.link} href="https://app.izidz.com/documents/terms.pdf" target='_blank'>Правила использования сервиса</a>
                    </div>
                </div>
                <div className={styles.firstRight}>
                    <img alt="/" src={mainFirst} />
                    <div className={styles.download}>
                        <span>Скачать приложение</span>
                        <div className={styles.dowload_block}>
                            <a href='https://apps.apple.com/app/id376771144' target='_blank'>
                                <img className={styles.ios} alt="/" src={download_ios} />
                            </a>
                            <a href='https://play.google.com/store/apps/details?id=ru.izidz.IZIDZ' target='_blank' className={styles.lastImage} >
                                <img alt="/" src={download_android} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <Second />
            <Third />
            <Fourth />
            <Fiveth />
            <div className={styles.block}>
                <h2 className={styles.blockTitle}>Домашнее задание – это ИЗИ!<br /> Присоединяйся прямо сейчас.</h2>
                <div className={styles.formPhoneBlock}>
                    <MaskInput
                        onChange={(value) => dispatch(setPhone(value))}
                        value={phone}
                        label="Номер телефона"
                    />
                    <div className={styles.marginButtonLeft}>
                        <Button width={48} onClick={() => history.push(navigationLink.mainboard)}>
                            <Arrow />
                        </Button>
                    </div>
                </div>
            </div>
            <p className={styles.info}>
                Кстати, если вы хотите поучаствовать в создании и проверке контента,
                или у вас есть предложени/замечания по приложению,
                а также по вопросам сотрудничества, пишите нам на <a href="mailto://hello@izidz.com">hello@izidz.ru</a>. Мы будем рады!
            </p>
        </div>
    );
}

export default MainPage;
