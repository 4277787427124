import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

const CssTextField = withStyles({
    root: {
        '& .MuiInputBase-root': {
            color: '#111111',
            fontWeight: 600,
            fontSize: 18,
            fontFamily: 'Montserrat, sans-serif',
        },
        '& label': {
            color: '#858585',
            fontWeight: 600,
            fontSize: 18,
            fontFamily: 'Montserrat, sans-serif',
        },
        '& label.Mui-focused': {
            color: '#858585',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'red',
            },
            '&:hover fieldset': {
                borderColor: 'yellow',
            },

        },
    },
})(TextField);


export const Input = (props) => {
    return (
        <CssTextField {...props} />
    )
}

export default Input;
