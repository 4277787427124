import React from 'react';
import { render } from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store'
import { Provider } from 'react-redux'
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { YMInitializer } from "react-yandex-metrika";

const fpPromise = FingerprintJS.load();
(async () => {
    // Get the visitor identifier when you need it.
    const fp = await fpPromise
    const result = await fp.get()

    // This is the visitor identifier:
    const visitorId = result.visitorId
    localStorage.setItem('udid', visitorId);
})()

const rootElement = document.getElementById("root");
render(
    <Provider store={store}>
        <App />
    </Provider>,
    rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
