import { postReq } from "../../utils";
import {navigationLink, pathsApi} from "../../consts";
import { useEffect, useState, useRef } from "react";
import {useHistory} from "react-router-dom";

export const useGetUdid = () => {
    useEffect(() => {
            const fetchData = async () => {
                const data = await postReq(pathsApi.register, {
                    udid: localStorage.getItem('udid'),
                }, true)
                localStorage.setItem('jwt', data?.refreshJwt)
            };
            fetchData();
    }, [])
}

export const useSetPhone = (phone, onNextScreen, notRedirect) => {
    const [error, setError] = useState(false);
    const [fetch, setFetch] = useState(false);

    const handleNext = async () => {
        if (!fetch) {
            setError(false)
            setFetch(true)

            const data = await postReq(pathsApi.sendPhoneConfirmation, {
                phone: phone.replace(/[^0-9+]/g, '')
            }, true)

            setFetch(false)
            if (data?.sent) {
                setError(false)
                if (!notRedirect) {
                    onNextScreen()
                }
            } else {
                setError(true)
            }
        }
    };

    return [error, handleNext]
}

export const useConfirmPhone = (phone, onNextScreen, smsText, setUserId) => {
    const [error, setError] = useState(false);
    const [fetch, setFetch] = useState(false);
    const history = useHistory()

    const onSubmitConfirmation = async () => {
        if (!fetch) {
            setError(false)
            setFetch(true)

            const data = await postReq(pathsApi.phoneConfirmation, {
                phoneNumber: phone.replace(/[^0-9+]/g, ''),
                confirmationToken: smsText,
            }, true)

            setFetch(false)
            if (data?.user?.confirmed) {
                setError(false)
                localStorage.setItem('jwt', data.refreshJwt)
                if (data.user.classNumber && data.user.username) {
                    history.push(navigationLink.mainboard)
                } else {
                    setUserId(data.user.id)
                    onNextScreen()
                }

            } else {
                setError(true)
            }
        }
    };

    return [error, onSubmitConfirmation]
}

export const useInterval = (callback, delay) => {
    const savedCallback = useRef(callback)
    useEffect(() => {
        savedCallback.current = callback
    }, [callback])

    useEffect(() => {
        if (delay === null) {
            return
        }
        const id = setInterval(() => savedCallback.current(), delay)

        return () => clearInterval(id)
    }, [delay])
}
