import styles from "./authorization.module.css";
import { Button, Input } from "@shared";
import React, { useState } from "react";
import cn from 'classnames'
import { useHistory } from "react-router-dom";
import { navigationLink } from '@consts'
import { putReq } from "../../utils";
import { pathsApi } from "../../consts";
import { useClassList } from "../../hooks";
import dayjs from "dayjs";

const CLASSES = new Array(11).fill(0).map((item, index) => `${index + 1} класс`);

export const ThirdScreen = ({ userId }) => {
    const [name, setName] = useState('');
    const [activeClass, setClass] = useState(null);
    const [error, setError] = useState(false);
    const [fetch, setFetch] = useState(false);
    const history = useHistory();
    const classesList = useClassList()
    const handleChange = (event) => {
        setName(event.target.value);
    };
    const isNext = !!name && !!activeClass;
    const isClassView = (date) => {
        if (date) {
            return dayjs().isBefore(dayjs.unix(date))
        } else {
            return false
        }
    }
    const handleClick = async () => {
        const data = await putReq(`${pathsApi.users}/${userId}`, {
            username: name,
            classNumber: activeClass,
        })

        setFetch(false)
        if (data && data.username) {
            history.push(`${navigationLink.addBook}`)
        } else {
            setError(true)
        }
    }

    return (
        <>
            <h1 className={styles.title}>Давай знакомиться!</h1>
            <div className={styles.inputBlock}>
                <Input
                    label="Как тебя зовут?"
                    value={name}
                    onChange={handleChange}
                />
            </div>
            <h3>В каком ты классе?</h3>
            <div className={styles.classes}>
                {
                    CLASSES.map((item, index) => (
                        <div
                            key={index}
                            className={cn(
                                styles.class,
                                {
                                    [styles.activeClass]: activeClass === index + 1,
                                    [styles.disabledClass]: isClassView(classesList?.schedule?.[index]?.date)
                                })
                            }
                            onClick={() => !isClassView(classesList?.schedule?.[index]?.date) ? setClass(index + 1) : undefined}
                        >
                            {item}
                        </div>
                    ))
                }
            </div>
            {
                isNext && (
                    <Button width={240} onClick={handleClick}>
                        Дальше
                    </Button>
                )
            }
        </>
    );
}

export default ThirdScreen;
