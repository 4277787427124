import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getReq } from "../utils";
import { pathsApi } from "../consts";

const initialState = {
    book: {},
    selectedExercise: {}
}

export const fetchBookById = createAsyncThunk(
    'book/getById',
    async (idBook) => {
        const response = await getReq(`${pathsApi.books}/${idBook}`)
        return response
    }
)

export const bookStore = createSlice({
    initialState,
    name: 'book',
    reducers: {
        addExercise: (state, action) => {
            state.selectedExercise[action.payload.id] = action.payload
        },
        deleteExercise: (state, action) => {
            delete state.selectedExercise[action.payload.id]
        },
        clear: (state, action) => {
            state.selectedExercise = {}
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchBookById.fulfilled, (state, action) => {
            state.book = action.payload
        })
    },
})

export const { addExercise, deleteExercise, clear } = bookStore.actions
export default bookStore.reducer
