import MaskedInput from 'react-text-mask';
import { regex } from '@consts';
import { Input } from '@shared'

function TextMaskCustom(props) {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={regex.ruPhone}

        />
    );
}

export const MaskInput = ({ onChange, value, label = 'Phone', ...other }) => {
    const handleChange = (event) => {
        onChange(event.target.value);
    };
    return (
        <Input
            {...other}
            label={label}
            value={value}
            onChange={handleChange}
            name="textmask"
            id="formatted-text-mask-input"
            InputProps={{
                inputComponent: TextMaskCustom,
            }}
        />
    )
}

export default MaskInput;
