import { useEffect } from 'react'
import { useDispatch } from "react-redux";
import { MySubject, FirstDashboard, History } from '@components'
import { fetchProgress } from "../../modules/progress";

export const Dashboard = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchProgress())
    }, [])

    return (
        <>
            <FirstDashboard />
            <MySubject />
            <History />
        </>
    );
}

export default Dashboard;
