import { useEffect, useState } from "react";
import {getReq} from "../utils";
import { pathsApi } from "../consts";

export const useDisciplinesList = () => {
    const [disciplines, setDiscipline] = useState(null)

    useEffect(() => {
        const getDisciplines = async () => {
            const data = await getReq(pathsApi.disciplines)
            setDiscipline(data)
        }
        getDisciplines();
    }, [setDiscipline])

    return disciplines
}

export const useSetBook = () => {
    const [checked, setCheck] = useState({})

    const handleCheck = (idDiscipline, idBook) => {
        let cloneChecked = { ...checked }
        if (!cloneChecked[idDiscipline]) {
            cloneChecked[idDiscipline] = idBook
        } else {
            if (cloneChecked[idDiscipline] === idBook) {
                delete cloneChecked[idDiscipline];
            } else {
                cloneChecked[idDiscipline] = idBook
            }
        }
        setCheck(() => cloneChecked)
    }

    return [checked, handleCheck]
}
