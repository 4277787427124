import {useEffect, useState} from "react";
import {getReq} from "../utils";
import {pathsApi} from "../consts";

export function useOutsideAlerter(ref, callback) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

export function useClassList(ref, callback) {
    const [classes, setClasses] = useState(null)

    useEffect(() => {
        const getClasses = async () => {
            const data = await getReq(pathsApi.contentSchedule)
            setClasses(data)
        }
        getClasses();
    }, [setClasses])

    return classes
}
