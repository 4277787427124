import { useCallback, useState } from 'react'
import { FirstAuthorization, SecondAuthorization, ThirdAuthorization } from '@components'
export const Authorization = () => {
    const [activeScreen, setScreen] = useState(0);
    const [userId, setUserId] = useState(null);
    const onNextScreen = useCallback(() => setScreen((prev) => prev + 1), []);
    const onPrevScreen = useCallback(() => setScreen((prev) => prev - 1), []);
    const content = [
        <FirstAuthorization onNextScreen={onNextScreen} />,
        <SecondAuthorization
            onNextScreen={onNextScreen}
            onPrevScreen={onPrevScreen}
            setUserId={setUserId}
        />,
        <ThirdAuthorization userId={userId} />
        ];
    return (
        <div>
            {content[activeScreen]}
        </div>
    );
}

export default Authorization;
