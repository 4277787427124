import {useSelector} from "react-redux";
import {selectSearch} from "../../../modules/search.selector";
import { useHistory } from 'react-router-dom'
import { navigationLink } from '@consts'
import styles from './styles.module.css'
import { postReq, getImage } from "../../../utils";
import {pathsApi} from "../../../consts";

export const ListSearch = () => {
    const search = useSelector(selectSearch)
    const history = useHistory()
    const handleRedirect = async (item) => {
        try {
            const data = await postReq(pathsApi.addProgress, {
                exerciseIds: [item?.id],
            })
            if (data) {
                history.push(`${navigationLink.exercise}/${item?.discipline?.id}`)
            } else {
                console.log(data)
            }
        } catch (e) {
            console.log(e)
        }
    }
    const pattern =  /\(\/[\S].+?(\))|(\!\[[\S].+?(\]))/g;
    return (
        <div className={styles.container}>
            {
                Array.isArray(search) && search?.map((item) => (
                    <div key={item.id} className={styles.item} onClick={() => handleRedirect(item)}>
                        <span className={styles.number}>№{item?.number}</span>
                        <span className={styles.description}>{item?.description?.replaceAll(pattern, '')}</span>
                        <div className={styles.block}>
                            <div className={styles.left}>
                                <img alt={'/'} src={getImage(item?.book?.cover?.url)}  />
                            </div>
                            <div className={styles.right}>
                                <span className={styles.name}>{item?.book?.name} {item?.book?.author}</span>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}
