import Dialog from '@material-ui/core/Dialog';
import styles from './styles.module.css'
import MuiDialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import doneImage from '../../../assets/images/doneImage.svg'
import { Button, ButtonImage } from "../../shared";
import React from "react";
import { useHistory } from "react-router-dom";
import { navigationLink } from "../../../consts";
import hand_img from "../../../assets/smiles/hand.svg";
import notUnderstand from "../../../assets/smiles/notUnderstand.svg";

const DialogContent = withStyles(() => ({
    root: {
        padding: 50,
        width: 980,
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
}))(MuiDialogContent);

export const ModalDone = ({ handleClose, open }) => {
    const history = useHistory();
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            PaperProps={{
                style: {
                    borderRadius: 16,
                    maxWidth: 980,
                    overflow: 'visible'
                }
            }}
        >
            <DialogContent>
                <img alt='/' src={doneImage} />
                <span className={styles.title}>Отлично!</span>
                <p className={styles.description}>
                    Мы разобрались с предметом. <br />
                    Но нам все еще есть над чем поработать...
                </p>
                <div className={styles.marginBottom}>
                    <Button width={256} onClick={() => history.push(navigationLink.mainboard)} image={hand_img}>
                        Сделать еще домашку
                    </Button>
                </div>
                <ButtonImage
                    color={'#666666'}
                    fontWeight={500}
                    onClick={() => history.push(navigationLink.mainboard)}
                    image={notUnderstand}
                >
                    На сегодня хватит
                </ButtonImage>
            </DialogContent>
        </Dialog>
    );
}

export default ModalDone;
