import { useEffect, useRef, useState } from 'react'
import styles from './styles.module.css'
import ReactHlsPlayer from 'react-hls-player';
import play_img from '../../../assets/svg/play_arrow_white_24dp.svg'
import fullscreen_exit from '../../../assets/svg/fullscreen_exit_white_36dp.svg'
import pause_img from '../../../assets/svg/pause.svg'
import fullscreen from '../../../assets/svg/fullscreen_white_36dp.svg'
import setting from '../../../assets/svg/setting.svg'
import { withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import { getTime, getImageVolume } from './helper'
import Fade from '@material-ui/core/Fade';
import cn from 'classnames'


const SoundSlider = withStyles({
    root: {
        color: '#FF6B55',
        height: 4,
        paddingBottom: 4,
        paddingTop: 4
    },
    thumb: {
        height: 8,
        width: 8,
        backgroundColor: '#FF6B55',
        marginTop: -2,
        marginLeft: -6,
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 4,
        borderRadius: 4,
    },
    rail: {
        height: 4,
        borderRadius: 4,
        color: '#f8f8f8',
    },
})(Slider);

const PrettoSlider = withStyles({
    root: {
        color: '#FF6B55',
        height: 4,
        marginTop: 47,
        paddingBottom: 8,
    },
    thumb: {
        display: 'none',
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 4,
        borderRadius: 4,
    },
    rail: {
        height: 4,
        borderRadius: 4,
        color: '#f8f8f8',
    },
})(Slider);

export const VideoComponent = ({ activeVideo, titleText }) => {
    const playerRef = useRef(null);
    const wrapperRef = useRef(null);
    const [currentTime, setCurrentTime] = useState(0);
    const [volume, setVolume] = useState(1);
    const [muted, setMuted] = useState(false);
    const [paused, setPaused] = useState(true);
    const [duration, setDuration] = useState(0);
    const [checked, setChecked] = useState(false);
    const [isViewMask, setViewMask] = useState(true);
    const [isFullscreen, setFullscreen] = useState(false);
    useEffect(() => {
        if (playerRef.current) {
            playerRef.current.pause();
            playerRef.current.currentTime = 0;
        }
    }, [activeVideo])
    const imageVolume = getImageVolume(volume)

    const onMouseMove = () => {
        setChecked(true)
    }
    const onMouseLeave = () => {
        setChecked(false)
    }

    const playVideo = () => {
        playerRef.current.play()
    };
    const pauseVideo = () => {
        playerRef.current.pause();
    };

    const handlePlay = () => {
        if (paused) {
            playVideo()
        } else {
            pauseVideo()
        }
    }

    const handleChangeSlider = (e, value) => {
        playerRef.current.currentTime = value;
    }

    const onChangeVolume = (e, value) => {
        if (playerRef?.current) playerRef.current.volume = value;
    }

    useEffect(() => {
        setPaused(true)
    },[activeVideo])

    useEffect(() => {
        let cleanupFunction = false;
        if (playerRef?.current) {
            let player = playerRef.current;
            player.addEventListener("timeupdate", () => {
                if (!cleanupFunction) {
                    setCurrentTime(player?.currentTime)
                }
            });
            player.addEventListener("volumechange", () => setVolume(player?.volume));
            player.addEventListener("pause", () => setPaused(true));
            player.addEventListener("play", () => setPaused(false));
            player.addEventListener("durationchange", () => setDuration(player?.duration));
        }
        return () => {
            cleanupFunction = true;
        }
    }, []);
    const handleClickPlay = () => {
        if (paused) {
            playVideo()
        } else {
            pauseVideo()
        }
    }

    const handleClickFullScreen = () => {
        if (isFullscreen) {
            document.exitFullscreen();
        } else {
            wrapperRef.current.requestFullscreen()
        }
        setFullscreen((prev) => !prev)
    }

    useEffect(() => {
        wrapperRef?.current?.addEventListener("fullscreenchange", (e) => {
            if (document.fullscreenElement !== null) {
                //entered
            } else {
                setFullscreen(false)
            }
        });
    }, [])

    const maxTime = getTime(duration);
    const currentTimeText = getTime(currentTime);
    const handleMouseMove = () => {
        if (!isViewMask) {
            setViewMask(true)
        }
    }
    const handleMouseLeave = () => {
        if (paused) {
            setViewMask(true)
        } else {
            setViewMask(false)
        }

    }

    return (
        <div className={styles.container} ref={wrapperRef}>
            <ReactHlsPlayer
                playerRef={playerRef}
                src={activeVideo?.url || ''}
                autoPlay={false}
                controls={false}
                width="100%"
                height="100%"
            />
            <div className={cn(styles.maskVideo)} onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave}>
                <div className={cn(styles.top, { [styles.showMaskTop]: isViewMask })}>
                    <span className={styles.name}>{titleText}</span>
                </div>
                <div className={styles.body} onClick={handlePlay}>
                    {
                        paused && <img className={styles.play} alt='/' src={play_img} onClick={playVideo} />
                    }
                </div>
                <div className={cn(styles.bottom, { [styles.showMaskBottom]: isViewMask })}>
                    <PrettoSlider value={currentTime} max={duration} onChange={handleChangeSlider} />
                    <div className={styles.navigation}>

                        <div className={styles.left}>
                            <img alt="/" src={paused ? play_img : pause_img} className={styles.controller} onClick={handleClickPlay} />
                            <img alt="/" src={imageVolume} className={styles.sound} onMouseMove={onMouseMove} onMouseLeave={onMouseLeave} />
                            <div>
                                <Fade in={checked} mountOnEnter unmountOnExit>
                                    <div className={styles.volume} onMouseMove={onMouseMove} onMouseLeave={onMouseLeave}>
                                        <SoundSlider
                                            value={volume} min={0}
                                            max={1}
                                            step={0.01}
                                            onChange={onChangeVolume}
                                        />
                                    </div>
                                </Fade>
                            </div>
                            <div className={styles.time}>
                                <span className={styles.currentTime}>{currentTimeText} </span>
                                <span className={styles.duration}>/ {maxTime}</span>
                            </div>
                        </div>

                        <div className={styles.right}>
                            {/*<img className={styles.settings} alt="/" src={setting} />*/}
                            <img
                                className={styles.settings}
                                onClick={handleClickFullScreen}
                                alt="/"
                                src={isFullscreen ? fullscreen_exit : fullscreen}
                            />
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default VideoComponent;
