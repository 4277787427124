import { createSelector } from "reselect";

export const selectProgress = state => state.dataProgress?.data;
export const selectHomeWorks = createSelector(
    selectProgress,
    (state) =>  state?.progress?.currentHomeWork?.disciplines
)

export const selectHomeWork = (id) => createSelector(
    selectHomeWorks,
    (state) =>  state?.find((item) => item.id.toString() === id)
)
