import { useRef } from 'react'
import { useOutsideAlerter } from '../../../hooks/helpersHooks'
import { logOut } from '../../../utils'
import { useHistory } from 'react-router-dom'
import exit_img from '../../../assets/svg/exit.svg'
import profile_img from '../../../assets/svg/profile.svg'
import cn from 'classnames'
import { navigationLink } from '../../../consts'
import styles from "./styles.module.css";
import { useDispatch } from "react-redux";
import { clearUser } from '../../../modules/user'

const defaultContent = [
    {
        id: 1,
        name: 'Профиль',
        img: profile_img
    },
]
export const Menu = ({ content = defaultContent, handleClose, isOpenMenu }) => {
    const dispatch = useDispatch()
    const wrapperRef = useRef(null);
    const history = useHistory()
    useOutsideAlerter(wrapperRef, handleClose);
    if (!isOpenMenu) {
        return null;
    }

    const onClickMenu = (item) => {
        if (item.id === 1) {
            history.push(navigationLink.profile)
        }
        handleClose()
    }
    const onClose = () => {
        dispatch(clearUser())
        logOut();
        window.location.replace(process.env.REACT_APP_HOME_PAGE);
        handleClose()
    }

    return (
        <div className={styles.container} ref={wrapperRef}>
            {
                content.map((item) => (
                    <div key={item.id} className={styles.menu} onClick={() => onClickMenu(item)}>
                        <img alt='/' src={item.img} />
                        {item.name}
                    </div>
                ))
            }
            <div className={cn(styles.menu, styles.exit)} onClick={onClose}>
                <img alt='/' src={exit_img} />
                Выход
            </div>
        </div>
    );
}

export default Menu;
