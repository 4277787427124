export const navigationLink = {
    main: '/',
    authorization: '/authorization',
    profile: '/profile',
    dashboard: '/dashboard',
    books: '/book',
    exercise: '/exercise',
    addBook: '/add-book',
    search: '/search',
    check: '/check-purchase',
    mainboard: '/',
}

export const pathsApi = {
    register: '/auth/register',
    sendPhoneConfirmation: '/auth/send-phone-confirmation',
    phoneConfirmation: '/auth/phone-confirmation',
    users: '/users',
    addAvatar: '/users/me/add-avatar',
    usersMe: '/users/me',
    stories: '/stories',
    disciplines: '/disciplines/v1',
    books: '/books',
    addProgress: '/users/me/add-progress',
    meProgress: '/users/me/progress',
    saveBooks: '/users/me/save-books',
    exercises: '/exercises',
    purchase: '/purchase/web/create-subscribe',
    purchaseCheck: '/purchase/web/check-subscribe',
    revoke: '/purchase/web/revoke-subscribe',
    contentSchedule: '/content-schedule'
}

export const regex = {
    ruPhone: ['+', '7', ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/,/\d/],
}

export const URL = 'https://stage.api.izidz.com'
