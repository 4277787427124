import Dialog from '@material-ui/core/Dialog';
import styles from '../ModalEnd/styles.module.css'
import MuiDialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import close_btn from '../../../assets/svg/closeBtn.svg'
import { Button, ButtonImage } from "../../shared";
import round_smile from '../../../assets/smiles/roundSmile.svg'

const DialogContent = withStyles(() => ({
    root: {
        padding: 24,
        width: 360,
        boxSizing: 'border-box',
    },
}))(MuiDialogContent);

export const ModalNextVideo = ({ handleClose, open, handleNextExercises, handleNextVideo }) => {

    const onNextExercises = () => {
        handleNextExercises()
        handleClose()
    }

    const onNextVideo = () => {
        handleNextVideo()
        handleClose()
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            PaperProps={{
                style: { borderRadius: 20 }
            }}
        >
            <DialogContent>
                <div className={styles.header}>
                    <span className={styles.title}><img alt="/" src={round_smile}/> Ничего страшного</span>
                    <img className={styles.close} alt={'/'} src={close_btn} onClick={handleClose} />
                </div>
                <div className={styles.description}>
                    У нас есть ещё вариант объяснения. <br/>
                    Ты можешь перейти к нему или <br/>
                    посмотреть этот заново.
                </div>
                <div className={styles.controllers}>
                    <div className={styles.marginBottom}>
                        <Button width={300} onClick={onNextVideo}>Перейти к другому варианту</Button>
                    </div>
                    <div className={styles.marginBottom}>
                        <ButtonImage onClick={handleClose}>Посмотреть ещё раз</ButtonImage>
                    </div>
                    <ButtonImage color={'#666666'} fontWeight={500} onClick={onNextExercises}>К следующиему упражнению</ButtonImage>
                </div>
            </DialogContent>
        </Dialog>
    );
}

export default ModalNextVideo;
