import {createSelector} from "reselect";

const MAX_COUNT = 5_000_000_000;

export const selectBooks = state => state.dataBooks;

export const selectSelectedExercise = createSelector(
    selectBooks,
    (state) =>  state?.selectedExercise
)

export const selectExerciseView = createSelector(
    selectSelectedExercise,
    (state) => {
        let result = {}
        Object.values(state).forEach((item) => {
            const number = item.number;
            if (!result[number]) {
                result[number] = {
                    subnumber: item.subnumber
                }
            } else {
                result[number].subnumber = `${result[number].subnumber}, ${item.subnumber}`
            }
        })
        return result;
    }
)


const getGroupsExercises = (exercises) => {
    let result = {}
    for (let i = 0; i < exercises.length; i++) {
        const item = exercises[i];
        const { number } = item
        if (!result[number]) {
            result[number] = [item]
        } else {
            result[number].push(item)
        }
    }
    return result;
}

const getResultSortedExercises = (groupsExercises) => {
    let count = MAX_COUNT;
    let currentId = 0
    let result = {}

    Object.keys(groupsExercises).forEach((key, index) => {
        const isLastIndex = Object.keys(groupsExercises).length - 1 === index;
        const item = groupsExercises[key]
        if (count === MAX_COUNT) {
            currentId++
        }
        if (count) {
            if (!result[currentId]) {
                result[currentId] = {
                    items: [item]
                }
            } else {
                result[currentId].items.push(item)
            }
            if (count === MAX_COUNT) {
                result[currentId].firstNumber = item[0].number
            }
            if (isLastIndex) {
                result[currentId].lastNumber = item[0].number
            }
            count--
        } else {
            result[currentId].items.push(item)
            result[currentId].lastNumber = item[0].number
            count = MAX_COUNT
        }
    })
    return result;
}

export const selectBook = (state) => state.dataBooks.book

export const selectGroupBook = createSelector(selectBook, (state) => {
    const exercises = state?.exercises;
    if (!exercises) return {};
    const groupsExercises = getGroupsExercises(exercises)
    return getResultSortedExercises(groupsExercises);
})

