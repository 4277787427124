import { Tab, VideoComponent, Controllers } from '../../components'
import styles from './styles.module.css'
import { useEffect, useMemo } from "react";
import { fetchProgress } from '../../modules/progress'
import { selectHomeWork } from '../../modules/progress.selector'
import { useDispatch, useSelector } from "react-redux";
import { useState } from 'react'
import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import {getImage, sortExercises} from "../../utils";
import Tooltip from '@material-ui/core/Tooltip';
import {fetchUserById} from "../../modules/user";

export const Exercise = () => {
    const { id } = useParams()
    const { exercises: noSortedEx } = useSelector(selectHomeWork(id)) || {}
    const [activeTabId, setTabId] = useState(0)
    const [activeVideoIndex, setVideoIndex] = useState(0)
    const dispatch = useDispatch()
    const exercises = sortExercises(noSortedEx)

    useEffect(() => {
        dispatch(fetchProgress())
    }, [])

    const activeContent = useMemo(() => exercises?.[activeTabId],[exercises, activeTabId])
    const activeVideo = activeContent?.explanations?.[activeVideoIndex]
    const handleNextExercises = () => {
        setVideoIndex(0)
        setTabId(prev => prev + 1)
    };
    const handleNextVideo = () => setVideoIndex(prev => prev + 1);
    const handlePrevVideo = () => setVideoIndex(prev => prev - 1);
    const isLastExercises = activeTabId === exercises?.length - 1;
    const book = activeContent?.book;
    const titleText = `${book?.name} ${book?.author}`
    const description = activeContent?.description?.replaceAll('/uploads', `${process.env.REACT_APP_URL}/uploads`)
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <Tab
                    exercises={exercises}
                    handelChangeTab={setTabId}
                    activeTabId={activeTabId}
                    setVideoIndex={setVideoIndex}
                />
                <VideoComponent activeVideo={activeVideo} titleText={titleText} />
                <Controllers
                    activeContent={activeContent}
                    activeVideoIndex={activeVideoIndex}
                    setVideoIndex={setVideoIndex}
                    handleNextExercises={handleNextExercises}
                    handlePrevVideo={handlePrevVideo}
                    handleNextVideo={handleNextVideo}
                    isLastExercises={isLastExercises}
                    activeVideo={activeVideo}
                />
                <div>
                    <ReactMarkdown className={styles.description}>{description}</ReactMarkdown>
                </div>
                <Tooltip title={activeContent?.origin || ''}>
                    <div className={styles.coppire}>&#169; Источник</div>
                </Tooltip>
            </div>
        </div>
    );
}

export default Exercise;
