import { useDispatch, useSelector } from "react-redux";
import { useCallback, useState } from 'react'
import { selectGroupBook, selectSelectedExercise } from "../../../pages/Book/select";
import { addExercise, deleteExercise } from '../../../modules/books'
import cn from 'classnames'
import ModalComponent from '../Modal/ModalComponent'
import styles from './styles.module.css'
import { openSubModal } from '../../../modules/controllers'
import lock from '../../../assets/svg/lock.svg'
import {sortNumbersWithStrings} from "../../../utils";

const GridItem = ({ item, handleClickOpen, setActiveItem }) => {
    const dispatch = useDispatch();
    const exercises = useSelector(selectSelectedExercise)
    const activeSubscribe = useSelector(state => state?.user?.user?.activeSubscribe)
    const firstItem = item?.[0];
    let subNumbers;
    if (item.length > 1) {
        const sortArray = sortNumbersWithStrings(item)
        subNumbers = {
            first: sortArray[0]?.subnumber,
            last: sortArray[sortArray.length - 1]?.subnumber,
        }
    }

    const handleClick = () => {
        if (item.length > 1) {
            setActiveItem(item)
            handleClickOpen()
        } else {

            if (exercises[firstItem.id]) {
                dispatch(deleteExercise(firstItem))
            } else {
                if (firstItem.freeAccess || activeSubscribe) {
                    dispatch(addExercise(firstItem))
                } else {
                    dispatch(openSubModal());
                }
            }
        }
    }
    const isActive = Object.values(exercises)?.some((ex) => ex.number === firstItem.number);
    return (
        <div
            className={cn(styles.exercise, { [styles.selected]: isActive })}
            onClick={handleClick}
        >
            {!activeSubscribe && !firstItem?.freeAccess && <img className={styles.lock} src={lock} alt='/' />}
            {firstItem?.number}
            {
                subNumbers && (
                    <div className={styles.subNumber}>{subNumbers.first} - {subNumbers.last}</div>
                )
            }
        </div>
    )
}

export const SecondGrid = ({ activePage }) => {
    const exercises = useSelector(selectGroupBook)
    const activeExercises = exercises?.[activePage];
    const [open, setOpen] = useState(false);
    const [activeItem, setActiveItem] = useState([]);

    const handleClickOpen = useCallback(() => {
        setOpen(true);
    }, [])

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div className={styles.container}>
            <h2>{activeExercises?.firstNumber} - {activeExercises?.lastNumber}</h2>
            <div className={styles.content}>
                {
                    activeExercises?.items?.map((item, index) => (
                        <GridItem key={index} item={item} handleClickOpen={handleClickOpen} setActiveItem={setActiveItem} />
                    ))
                }
            </div>
            {
                activeItem && (
                    <ModalComponent handleClose={handleClose} open={open} content={activeItem} />
                )
            }
        </div>

    )
}
