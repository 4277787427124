import Dialog from '@material-ui/core/Dialog';
import styles from './styles.module.css'
import { Button } from "../";
import { useHistory } from 'react-router-dom'
import { navigationLink } from '../../../consts'
import close_img from '../../../assets/svg/close_grey.svg'

export const SimpleModal = ({
     handleClose,
     open,
     onSubmit,
     redirectToDashboard,
     widthClose = 240,
     submitName = 'Добавить',
     title = 'Скоро появится',
     description = `К сожалению, у нас пока нет домашек для твоего класса. Но мы работаем над этим.
     Можем напомнить тебе по СМС сразу, как только добавим материалы для твоего класса.`,
}) => {
    const history = useHistory()
    const onClose = () => {
        if (redirectToDashboard) {
            history.push(navigationLink.mainboard)
        }
        handleClose()
    }
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            PaperProps={{
                style: {
                    maxWidth: 480,
                }
            }}
        >
            <div className={styles.container}>
                <img className={styles.closeIcon} alt='/' src={close_img} onClick={onClose} />
                <span className={styles.title}>{title}</span>
                <div className={styles.description}>
                    {description}
                </div>
                <div className={styles.controllers}>
                    <Button width={widthClose} onClick={onClose} opacityButton color={'rgba(255, 107, 85, 0.08)'}>
                        Закрыть
                    </Button>
                    {
                        onSubmit && (
                            <Button width={256} onClick={onSubmit}>
                                {submitName}
                            </Button>
                        )
                    }
                </div>
            </div>
        </Dialog>
    );
}

export default SimpleModal;
