import { useRouteMatch, Link } from "react-router-dom";
import { navigationLink } from '@consts'
import { Button } from "@shared";
import logo from '@assets/svg/logo.svg'
import close_blue from '@assets/svg/close_blue.svg'
import search_blue from '@assets/svg/search_blue.svg'
import support from '@assets/svg/support.svg'
import profile_photo from '@assets/svg/profile_photo.svg'
import { Menu } from '../'
import styles from './header.module.css'
import { useEffect, useState } from "react";
import { fetchSearch, clearSearch } from '../../../modules/search'
import { useDispatch, useSelector } from "react-redux";
import { fetchUserById } from "../../../modules/user";
import { useHistory } from 'react-router-dom'
import { throttle } from '../../../utils/helper'
import { getImage } from '../../../utils'
import { ModalSub } from '../../Subscribe/ModalSub'
import cn from 'classnames'

const navigation = [
    { id: 1, image: support, link: 'https://t.me/izidz_ask' },
    /*{ id: 2, image: message_nav },
    { id: 3, image: notification_nav },*/
];

export const Header = () => {
    const [isOpenMenu, setOpenMenu] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory()
    const user = useSelector(state => state?.user?.user)
    const isAuthorization = useRouteMatch({ path: navigationLink.authorization });

    const isSearch = useRouteMatch({ path: navigationLink.search, exact: true });
    const handleOpen = () => setOpenMenu(true);
    const handleClose = () => setOpenMenu(false)

    useEffect(() => {
        if (!!isAuthorization) {
            //without user
        } else {
            dispatch(fetchUserById())
        }
    }, [isAuthorization])

    let handleSearch = (e) => {
        if (e.target.value) {
            dispatch(fetchSearch(e.target.value))
        } else {
            dispatch(clearSearch())
        }

    }
    handleSearch = throttle(handleSearch, 1500)
    const onClickLogo = () => {
        if (isAuthorization) {
            window.location.replace(process.env.REACT_APP_HOME_PAGE);
        } else {
            history.push(navigationLink.mainboard)
        }
    }
    return (
        <div className={cn(styles.container, { [styles.searchStyle]: isSearch })}>
            <div onClick={onClickLogo} className={styles.logo}>
                <img src={logo} alt="Logo" />
            </div>
            {
                isSearch && (
                    <div className={styles.searchContainer}>
                        <input
                            className={styles.search}
                            placeholder="Поиск"
                            onChange={handleSearch}
                        />
                        <img alt={'/'} src={close_blue} onClick={() => history.push(navigationLink.mainboard)} />
                    </div>
                )
            }
            {
                !isAuthorization && (
                    <div className={styles.navigation}>
                        <ul className={styles.list}>
                            {
                                navigation.map((item) => {
                                    if (item.id === 1 && isSearch) {
                                        return null
                                    }
                                    return (
                                        <li key={item.id} className={styles.item}>
                                            <img alt={item.id} src={item.image} width={32} height={32} onClick={() => window.open(item.link)} />
                                        </li>
                                    )
                                })
                            }
                        </ul>
                        <div className={styles.profile}>
                            <div className={styles.containerPhoto}>
                                <img
                                    className={cn(styles.image, { [styles.defaultAvatar]: !user?.avatar })}
                                    src={user?.avatar?.url ? getImage(user?.avatar?.url) : profile_photo}
                                    alt="Logo"
                                    onClick={handleOpen}
                                />
                            </div>
                            <Menu isOpenMenu={isOpenMenu} handleClose={handleClose} />
                        </div>
                    </div>
                )
            }
            {/*{
                isMain && (
                    <Link to={navigationLink.authorization}>
                        <Button width={101}>Войти</Button>
                    </Link>
                )
            }*/}
            <ModalSub />
        </div>
    );
}

export default Header;
