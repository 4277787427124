import styles from './styles.module.css'
import cn from 'classnames'
import "slick-carousel/slick/slick-theme.css";

export function SampleNextArrow(props) {
    const { onClick, className } = props;
    const isDisabled = className?.indexOf('slick-disabled') !== -1;
    return (
        <div
            className={cn(styles.container, styles.containerRight, { [styles.disabled]: isDisabled })}
            onClick={onClick}
        >
            <div className={cn(styles.arrow, styles.right)} />
        </div>
    );
}

export function SamplePrevArrow(props) {
    const { onClick, className } = props;
    const isDisabled = className?.indexOf('slick-disabled') !== -1;
    return (
        <div
            className={cn(styles.container, styles.containerLeft, { [styles.disabled]: isDisabled })}
            onClick={onClick}
        >
            <div className={cn(styles.arrow, styles.left)} />
        </div>
    );
}
