import styles from './button.module.css'
import cn from 'classnames'

export const Button = ({ children, onClick = null, width = 240, disabled = false, opacityButton = false, color = '#FF6B55', imageLeft, image }) => {
    return (
        <button
            className={cn(styles.container, { [styles.opacityButton]: opacityButton })}
            onClick={onClick}
            style={{ width: `${width}px`, background: color }}
            disabled={disabled}>
            {
                imageLeft && <img className={styles.leftImage} alt="/" src={imageLeft} />
            }
            {children}
            {
                image && <img className={styles.image} alt="/" src={image} />
            }
        </button>
    );
}

export default Button;
