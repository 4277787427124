import { configureStore } from '@reduxjs/toolkit'
import user from './modules/user'
import dataBooks from './modules/books'
import dataProgress from './modules/progress'
import dataSearch from './modules/search'
import dataControllers from './modules/controllers'

export default configureStore({
    reducer: {
        user,
        dataBooks,
        dataProgress,
        dataSearch,
        dataControllers
    },
})
