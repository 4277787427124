import Header from "../shared/Header/Header";
import Footer from "../shared/Footer/Footer";
import styles from './wrapper.module.css'
import { useRouteMatch } from "react-router-dom";
import { navigationLink } from '@consts'
import cn from 'classnames'

export const Wrapper = ({ children }) => {
    const isAuthorization = useRouteMatch({ path: navigationLink.authorization });
    const isAddBook = useRouteMatch({ path: navigationLink.addBook, exact: true });
    const isSearch = useRouteMatch({ path: navigationLink.search, exact: true });
    const isWhiteBackground = isAuthorization || isAddBook || isSearch;
    return (
        <>
            <div className={styles.wrapper}>
                <Header />
            </div>
            <div className={cn(styles.content, { [styles.whiteBg]: isWhiteBackground })}>
                <div className={styles.wrapper}>
                    {children}
                </div>
            </div>
            <div className={styles.wrapper}>
                <Footer />
            </div>
        </>
    );
}

export default Wrapper;
