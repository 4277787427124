import styles from './styles.module.css'
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom'
import Slick from "react-slick";
import { SamplePrevArrow, SampleNextArrow, Progress } from '@shared'
import { selectHomeWorks } from '../../../modules/progress.selector'
import { generateSlideItems, getCurrentTextDay } from "../../../utils";
import { navigationLink } from '../../../consts'
import hi from '../../../assets/smiles/hi.svg'
import "slick-carousel/slick/slick.css";


const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
};

export const FirstScreen = () => {
    const history = useHistory();
    const name = useSelector(state => state?.user?.user?.username)
    const progress = useSelector(selectHomeWorks)
    const progressItems = progress ? generateSlideItems(progress, 3) : null;
    const handleRedirect = (id) => {{
        history.push(`${navigationLink.exercise}/${id}`)
    }}

    return (
        <div className={styles.container}>
            <div className={styles.left}>
                <h1>
                    Привет, {name}
                    <img className={styles.hiImage} alt='/' src={hi} />
                </h1>
                <p className={styles.description}>Сегодня {getCurrentTextDay()}, чтобы заняться <br /> домашкой.<br/>
                    Ведь это ИЗИ:<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;1) Смотри наши ролики с экрана компьютера, чтобы текст не был мелким. Береги свои глаза! Но если сейчас есть только телефон, просто переверни его горизонтально и растяни под размер экрана.<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;2) Жми кнопку “Добавить” и отметь нужные предметы.<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;3) Выбери номера домашки (можно все сразу!) и напитывайся знаниями. <br/>
                    <br/>
                    Успехов тебе!

                </p>
            </div>
            {
                progressItems && (
                    <div className={styles.right}>
                        <h2 className={styles.title}>Продолжить домашку</h2>
                        <div className={styles.slide}>
                            <Slick {...settings}>
                                {
                                    progressItems?.map((item, index) => {
                                        if (!item) return <div key={index} />;
                                        const exercises = item?.exercises
                                        const maxExercises = item?.exercises?.length;
                                        const numbers = `${exercises?.[0]?.number} - ${exercises?.[maxExercises - 1]?.number}`
                                        const successExercises = exercises?.reduce((previousValue, currentValue) => {
                                            if (currentValue?.explanations?.some((item) => !!item?.clear)) {
                                                return ++previousValue;
                                            }
                                            return previousValue;
                                        }, 0);
                                        return (
                                            <div key={item.id} onClick={() => handleRedirect(item.id)}>
                                                <div className={styles.item}>
                                                    <h3 className={styles.titleBlock}>{item.name}</h3>
                                                    <h3 className={styles.titleBlock}>№ {numbers}</h3>
                                                    <span className={styles.blackText}>{successExercises}</span>
                                                    <span className={styles.greyText}> / {maxExercises}
                                        </span>
                                                    <Progress progress={successExercises} count={maxExercises}  />
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </Slick>
                        </div>
                    </div>
                )
            }
        </div>
    );
}

export default FirstScreen;
