import styles from "./styles.module.css";
import Slick from "react-slick";
import { SamplePrevArrow, SampleNextArrow } from '@shared'
import {useEffect, useState} from "react";
import { getReq, generateSlideItems, getImage } from '../../../utils';
import { pathsApi, URL } from '@consts'
import { ModalHistory } from './ModalHistory'

const MAX_SLIDE_SHOW = 6;

const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: MAX_SLIDE_SHOW,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
};

export const History = () => {
    const [history, setHistory] = useState(null);
    const [open, setOpen] = useState(false);
    const [indexStory, setIndexStory] = useState(0);
    useEffect(() => {
        const getHistory = async () => {
            const data = await getReq(pathsApi.stories)
            setHistory(data)
        }
        getHistory();
    }, [setHistory])

    const handleClose = () => setOpen(false)
    const handleOpen = (index) => {
        setIndexStory(index)
        setOpen(true)
    }
    let media = [];
    if (Array.isArray(history)) {
        history.forEach((item) => {
            media = [...media, ...item.media]
        })
    }
    const slideItems = generateSlideItems(history, MAX_SLIDE_SHOW);
    const stories = Array.isArray(media) ? media.map((item) =>({ url: getImage(item?.url), type: item?.ext === '.mp4' ? 'video' : '' })) : [];
    return (
        <div className={styles.container}>
            <span className={styles.title}>А ты знал что...</span>
            <div className={styles.slide}>
                <Slick {...settings}>
                    {
                        Array.isArray(slideItems) && slideItems?.map((item, index) => {
                            if (!item) return <div key={index} />;
                            return (
                                <div key={item.id} onClick={() => handleOpen(index)}>
                                    <div className={styles.item}>
                                        <img className={styles.image} src={getImage(item?.preview?.url)} alt="story"/>
                                    </div>
                                </div>
                            )
                        })
                    }
                </Slick>
            </div>
            <ModalHistory open={open} stories={stories} handleClose={handleClose} indexStory={indexStory} />
        </div>
    )
}
