import styles from "./styles.module.css";
import check_blue from '../../../assets/svg/check_blue.svg'
import { getImage } from '../../../utils'
import { SimpleModal } from '../SimpleModal/SimpleModal'
import cn from 'classnames'
import {useEffect, useState} from "react";

export const ViewBook = ({ disciplines, checked, handleCheck, setRemove, removing }) => {
    const [isOpenError, setOpenError] = useState(false);
    useEffect(() => {
       if (Array.isArray(disciplines) && disciplines.length !== 0) {
           setOpenError(false)
       } else {
           if (disciplines === null) {
               setOpenError(false)
           } else {
               setOpenError(true)
           }
       }
    }, [disciplines])

    const handleClose = () => {
        setOpenError(false)
    }
    const onCheck = (id) => {
        const newChecked = {...checked}
        const newRemove = {...removing}
        if (!checked[id]) {
            newChecked[id] = id
            delete newRemove[id]
        } else {
            newRemove[id] = id;
            delete newChecked[id]
        }
        setRemove(newRemove)
        handleCheck(newChecked)
    }

    return (
        <>
            <SimpleModal open={isOpenError} handleClose={handleClose} redirectToDashboard />
            {
                Array.isArray(disciplines) && disciplines.map((discipline) => (
                    <div key={discipline.id}>
                        <span className={styles.disciplineName}>{discipline.name}</span>
                        <div className={styles.container}>
                            {
                                discipline?.books?.map((book) => (
                                    <div
                                        key={book.id}
                                        className={cn(styles.book, { [styles.active]: checked[book.id] })}
                                        onClick={() => onCheck(book.id)}
                                    >
                                        <div className={styles.checkContainer}>
                                            {
                                                checked[book.id] ? (
                                                    <img alt="/" src={check_blue} />
                                                ) : (
                                                    <div className={styles.check} />
                                                )
                                            }
                                        </div>
                                        <div className={styles.left}>
                                            <img alt="/" src={getImage(book?.cover?.url)} />
                                        </div>
                                        <div className={styles.right}>
                                            <span className={styles.titleName}>{book.name}</span>
                                            <span className={styles.author}>{book.author}</span>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                ))
            }
        </>
    );
}

export default ViewBook;
