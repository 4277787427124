import Dialog from '@material-ui/core/Dialog';
import styles from './styles.module.css'
import { postReq } from "../../../utils";
import { pathsApi } from "../../../consts";
import { Button, ViewBook } from "../../shared";
import { useDisciplinesList } from '../../../hooks/useDisciplines'
import { fetchUserById } from "../../../modules/user";
import { useDispatch, useSelector } from "react-redux";
import { selectUserBooks } from "../../../modules/user.selector";
import { useEffect, useState } from "react";

export const AddModal = ({ handleClose, open }) => {
    const dispatch = useDispatch()
    const disciplines = useDisciplinesList()
    const [checked, setCheck] = useState({})
    const [removing, setRemove] = useState({})
    const books = useSelector(selectUserBooks)
    const onSave = async () => {
        const ids = {}
        Object.keys(checked).forEach(item => {
            const isSave = books?.find((book) => item === book.id.toString())

            if (!isSave) {
                ids[item] = item;
            }
        })

        Object.keys(removing).forEach(item => {
            const isSave = books?.find((book) => item === book.id.toString())

            if (isSave) {
                ids[item] = item;
            }
        })


        const data = await postReq(pathsApi.saveBooks, {
            bookIds: Object.values(ids)?.map(item => item)
        })
        if (!data?.error) {
            dispatch(fetchUserById())
            handleClose()
        }
    }
    useEffect(() => {
        if (books) {
            if (!books.length) {
                return setCheck({})
            }
            const ids = {}
            books.forEach(item => {
                ids[item.id] = item.id;
            })
            setCheck({...checked, ...ids})
        }
    }, [books])
    const isShowBooks = Array.isArray(disciplines) && disciplines.length !== 0
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            PaperProps={{
                style: {
                    maxWidth: 720,
                }
            }}
        >
            <div className={styles.container}>
                <span className={styles.title}>{isShowBooks ? 'Добавить предмет' : 'Скоро появится'}</span>
                {
                    isShowBooks ? (
                        <ViewBook
                            checked={checked}
                            disciplines={disciplines}
                            handleCheck={setCheck}
                            setRemove={setRemove}
                            removing={removing}
                        />
                    ) : (
                        <div className={styles.errorText}>
                            К сожалению, у нас пока нет домашек для твоего класса. Но мы работаем над этим.
                            Можем напомнить тебе по СМС сразу, как только добавим материалы для твоего класса.
                        </div>
                    )
                }

                <div className={styles.controllers}>
                    <Button width={240} onClick={onSave}>
                        Сохранить
                    </Button>
                    <Button width={240} onClick={handleClose} opacityButton color={'rgba(255, 107, 85, 0.08)'}>
                        Отменить
                    </Button>
                </div>
            </div>
        </Dialog>
    );
}

export default AddModal;
