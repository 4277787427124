import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import styles from './styles.module.css'
import MuiDialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import close_btn from '../../../assets/svg/closeBtn.svg'
import { Button, ButtonImage } from "../../shared";

const DialogContent = withStyles(() => ({
    root: {
        padding: 24,
        width: 360,
        boxSizing: 'border-box',
    },
}))(MuiDialogContent);

export const ModalEnd = ({ handleClose, open, setVideoIndex, handleNextExercises }) => {
    const handleRestartVideos = () => {
        setVideoIndex(0);
        handleClose()
    }

    const onNextExercises = () => {
        handleNextExercises()
        handleClose()
    }
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            PaperProps={{
                style: { borderRadius: 20 }
            }}
        >
            <DialogContent>
                <div className={styles.header}>
                    <span className={styles.title}>Упс...</span>
                    <img className={styles.close} alt={'/'} src={close_btn} onClick={handleClose} />
                </div>
                <div className={styles.description}>
                    Кажется, у нас закончились варианты объяснений...<br />
                    Может посмотришь ещё раз сначала?
                </div>
                <div className={styles.controllers}>
                    <div className={styles.marginBottom}>
                        <Button width={173} onClick={handleRestartVideos}>Начать заново</Button>
                    </div>
                    <ButtonImage color={'#666666'} fontWeight={500} onClick={onNextExercises}>К следующиему упражнению</ButtonImage>
                </div>
            </DialogContent>
        </Dialog>
    );
}

export default ModalEnd;
